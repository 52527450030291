<template>
  <k-layout>
    <header class="tw-bg-white tw-px-4">
      <div class="tw-relative tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg tw-py-12 md:tw-py-20">
        <img
          src="./img/max.svg"
          class="tw-absolute tw-bottom-0 tw-right-0 tw-max-h-96 tw-hidden lg:tw-block tw-pointer-events-none"
        />

        <k-product-title :title="$t('questionaire.title')" />

        <h2 class="tw-mt-8 tw-heading-2" v-html="$t('questionaire.subtitles.experience')" />

        <div class="tw-flex tw-flex-col tw-items-start tw-mt-8 tw-space-y-2 sm:tw-flex-row sm:tw-space-y-0 sm:tw-space-x-2">
          <k-select
            :placeholder="$t('questionaire.inputs.choose_position')"
            :items="selectorOptions"
            v-model="selectedProfession"
            class="tw-w-full md:tw-max-w-xs"
            item-value="value"
            item-text="displayString"
            return-object
          />

          <k-button id="gtm_adequate_salary" @click="professionSelected">
            {{ $t('global.actions.continue') }}
          </k-button>
        </div>

        <p class="tw-max-w-xl tw-mt-6 tw-text-xs tw-text-gray-600">
          {{ $t('questionaire.descriptions.salary_evaluate') }}
        </p>
      </div>
    </header>

    <main class="tw-mt-8 tw-px-4 tw-mb-24">
      <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg">
        <div class="">
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-end">
            <h4 class="tw-heading-4">{{ $t('questionaire.subtitles.paid_jobs') }} 💼</h4>

            <p class="tw-mt-6 tw-flex-shrink-0 lg:tw-mt-0 tw-text-base">
              <k-link :to="{ name: 'project-index' }" class="tw-text-gray-500">{{ $t('questionaire.actions.all_jobs') }}
                ›
              </k-link>
            </p>
          </div>

          <div class="tw-grid tw-grid-cols-1 tw-gap-6 tw-mt-6">
            <project-preview :project="project" v-for="project in projects" :key="project.id" />
          </div>
        </div>
      </div>
    </main>
  </k-layout>
</template>

<script>
import Cookies from 'js-cookie'
import { mapGetters } from 'vuex'

import ProjectPreview from './../projects/components/project-preview-new'
import { getRouteLocale } from '@web/constants/language'

export default {
  name: 'QuestionairePage',
  components: {
    ProjectPreview
  },
  data() {
    return {
      selectedProfession: ''
    }
  },
  methods: {
    professionSelected() {
      const to = {
        name: 'MethodQuestionaire',
        params: {
          locale: getRouteLocale(),
          queryProfession: this.selectedProfession.value
        }
      }

      if (this.isUserSignedIn) {
        this.$store.dispatch('QUESTIONAIRE/setFormResultsProfession', this.selectedProfession.value)
        this.$router.push(to)
      } else {
        Cookies.set('redirect.after_login', JSON.stringify(to))
        this.$router.push({ name: 'auth-signup' })
      }
    }
  },
  computed: {
    ...mapGetters({
      allProjects: 'NEW_PROJECTS/getAllProjects',
      professions: 'ENUMS/getProfessions',
      isUserSignedIn: 'AUTH/getIsUserSignedIn'
    }),
    projects() {
      return this.allProjects.slice(0, 4)
    },
    selectorOptions() {
      return this.professions.filter(item => item.value !== 'OTHER')
    },
    locale() {
      return getRouteLocale()
    }
  }
}
</script>

<style lang="scss">
@import "@web/sass/variables.scss";

.questionaire-page {
  background-position: top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  .thank-you {
    padding-top: 2rem !important;
  }

  .section {
    width: 100%;

    .container {
      padding: 4rem 0;
      position: relative;
    }
  }

  .section--intro {
    background: transparent linear-gradient(180deg, #ffffff 0%, #eeeef1 100%) 0% 0% no-repeat padding-box;

    .container {
      padding-top: 8rem;
    }

    @include lg {
      .container {
        padding-top: 9rem;
        padding-bottom: 7rem;
      }
    }

    .kod-header--heading {
      margin-bottom: 0.25rem;

      display: flex;
      flex-direction: row;
      align-content: center;

      > img {
        @include margin-right-multiplier(1);
      }

      > span {
        font-family: "soleil", serif;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }

    .kod-header-questionaire-content {
      .section__position-select-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
      }

      .section__position-select {
        width: 100%;
        max-width: 35rem;
        margin-top: 1.5rem;

        .v-input {
          background-color: white !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          box-shadow: 0 0.063rem 0.25rem #1d1f291a;

          &.v-text-field .v-input__slot {
            padding-left: 1rem !important;
          }
        }

        .v-input__slot {
          height: 3rem !important;
          background-color: white !important;
          border: none !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        .v-input__append-inner {
          display: none;
        }
      }

      @media (max-width: 45rem) {
        .section__position-select {
          width: 100%;
          max-width: unset;
        }
        .section__position-select-wrapper {
          flex-direction: column;

          input {
            max-width: unset;
          }

          button {
            @include margin-top-multiplier(1);
            width: 100%;
          }
        }
      }

      .small-text {
        font-size: 0.75rem;
        color: $kod-grey;
        @include margin-top-multiplier(3);
      }

      @media (max-width: 64rem) {
        p.small-text {
          max-width: 43.375rem;
        }
      }
      @media (min-width: 48rem) {
        h3 {
          width: 29.125rem;
        }
        p {
          width: 100%;
        }
      }
    }
  }

  .questionaire-header {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: $kod-black;

    text-align: center;
    color: white;

    @media (max-width: 63.938rem) {
      @include padding-left-multiplier(2);
      @include padding-right-multiplier(2);
    }

    p {
      @include margin-top-multiplier(2);
      width: 100%;
      max-width: 42.75rem;

      &.extra-small-text {
        @include margin-top-multiplier(3);
        width: 100%;
        max-width: 33.875rem;
      }
    }

    .questionaire-input {
      @include margin-top-multiplier(5);
      @include padding-multiplier(1);

      // height: 4rem;
      width: 100%;
      max-width: 39rem;
      border-radius: 0.125rem;

      background: white;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 63.938rem) {
        max-width: 41rem;
        flex-direction: column;

        button {
          width: 100%;
        }
      }

      .questionaire-input-select {
        width: 100%;
        @media (max-width: 63.938rem) {
          padding: 0.5rem 0 0.5rem 0;
        }

        p {
          display: none;
        }

        .ka-form-select {
          .v-input__control {
            min-height: unset !important;
            @include padding-right-multiplier(2);

            .v-input__slot {
              height: 2.375rem;
              border: unset !important;
              background: white !important;
              @include margin-bottom-multiplier(0);

              .v-input__append-inner {
                display: none;
              }
            }
          }

          .v-select__selections {
            height: 100%;
            width: 25rem;
          }

          .v-text-field__details {
            display: none;
          }
        }
      }
    }
  }

  .questionaire-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include padding-bottom-multiplier(20);

    &.job-listing-container {
      padding-bottom: 2rem !important;
    }

    .extra-projects {
      @include margin-top-multiplier(5);
      width: 100%;
      max-width: 60rem;
      display: flex;
      flex-direction: column;
      align-content: flex-start;

      @media (max-width: 63.938rem) {
        max-width: 41rem;
        .kod-link {
          margin-left: 0.75rem;
        }
      }

      .questionaire-projects {
        @include margin-top-multiplier(3);

        @media (min-width: 64rem) {
          .col-12 {
            padding: 0 0 0.5rem;

            &:nth-child(2n) {
              padding-left: 0.75rem;
            }

            &:nth-child(2n + 1) {
              padding-right: 0.75rem;
            }
          }
        }

        @media (max-width: 64rem) {
          .col-12 {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
          }
        }

        .project-in-listing {
          @include margin-bottom-multiplier(2);

          .project-new-chip {
            display: none;
          }

          .project-listing-technologies {
            height: 1.5rem;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.mobile-select-container {
  width: 100%;
  max-width: 39rem;
  margin-top: 2.5rem;
}

.mobile-select {
  width: 100%;
  outline: none;
  color: $koderia-black;
  height: 3rem;
}

.mobile-select .v-input__control > .v-input__slot {
  padding: 0.625rem 0.5rem !important;
  height: unset !important;
  border: none !important;
}

.section {
  .container {
    padding: 4rem 0;
    position: relative;
  }
}

.section--intro {
  background: transparent linear-gradient(180deg, #ffffff 0%, #eeeef1 100%) 0% 0% no-repeat padding-box;

  .container {
    padding-top: 8rem;
  }

  @include lg {
    .container {
      padding-top: 10.75rem;
      padding-bottom: 7.75rem;
    }
  }
}

.container--content {
  width: 100%;
  @include lg {
    max-width: 39rem;
  }
}

span.section__title {
  font-size: 2rem !important;
  line-height: 2.625rem;
  font-family: "soleil", serif !important;
  font-weight: 500;

  @media (max-width: 60rem) {
    br {
      display: none;
    }
  }
}

.figure,
.figure * {
  -webkit-user-drag: none;
  pointer-events: none;
  user-select: none;
}

.figure--intro {
  position: absolute;
  width: 20.875rem;
  bottom: 0;
  right: -1.5rem;
  @include -lg {
    display: none;
  }
}

.section__position-select-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.section__position-select {
  width: 100%;
  max-width: 35rem;
  margin-top: 1.5rem;

  .v-input {
    background-color: white !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    box-shadow: 0 0.063rem 0.25rem #1d1f291a;

    &.v-text-field .v-input__slot {
      padding-left: 1rem !important;
    }
  }

  .v-input__slot {
    height: 3rem !important;
    background-color: white !important;
    border: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .v-input__append-inner {
    display: none;
  }
}

.section__position-button {
  width: 8.375rem;

  &.k-button {
    height: 3rem !important;
  }

  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.section__logo {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-content: center;

  > img {
    @include margin-right-multiplier(1);
  }

  > span {
    font-family: "soleil", serif;
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}
</style>
