import { render, staticRenderFns } from "./questionaire-profession-page.vue?vue&type=template&id=7365f3b1&"
import script from "./questionaire-profession-page.vue?vue&type=script&lang=js&"
export * from "./questionaire-profession-page.vue?vue&type=script&lang=js&"
import style0 from "./questionaire-profession-page.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KLayout from '@web/components/core/k-layout'
import KLink from '@web/components/core/k-link'
import KProductTitle from '@web/components/core/k-product-title'
import KSelect from '@web/components/core/k-select'
installComponents(component, {KButton,KLayout,KLink,KProductTitle,KSelect})
