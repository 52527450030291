function FileDropArea(elm, readAs, onFiles, onError, filterFileType) {
  if (typeof arguments[1] !== 'string') {
    filterFileType = arguments[3]
    onError = arguments[2]
    onFiles = arguments[1]
    readAs = null
  }

  if (readAs && ['readAsDataURL', 'readAsText', 'readAsArrayBuffer'].indexOf(readAs) === -1) throw new Error('Wrong arguments')

  this.elm = elm
  this.readAs = readAs
  this.onFiles = onFiles
  this.onError = onError
  this.filterFileTypeRegexp = filterFileType ? new RegExp(filterFileType) : null

  this.dragover = false
}

FileDropArea.prototype.dragoverClass = 'file-dragover'

FileDropArea.prototype.onDragEnter = function (e) {
  e.preventDefault()
  e.stopPropagation()
  this.dragover = true
  this.elm.classList.add(this.dragoverClass)
}

FileDropArea.prototype.onDragLeave = function (e) {
  e.preventDefault()
  e.stopPropagation()
  this.dragover = false
  this.elm.classList.remove(this.dragoverClass)
}

FileDropArea.prototype.bind = function () {
  var onDragEnter = this.onDragEnter.bind(this)
  var onDragLeave = this.onDragLeave.bind(this)
  var onDrop = this.onDrop.bind(this)

  this.elm.addEventListener('dragenter', onDragEnter, false)
  this.elm.addEventListener('dragover', onDragEnter, false)
  this.elm.addEventListener('dragleave', onDragLeave, false)
  this.elm.addEventListener('drop', onDrop, false)

  this.unbind = function () {
    this.elm.removeEventListener('dragenter', onDragEnter, false)
    this.elm.removeEventListener('dragover', onDragEnter, false)
    this.elm.removeEventListener('dragleave', onDragLeave, false)
    this.elm.removeEventListener('drop', onDrop, false)
    return this
  }

  return this
}

// files white list
FileDropArea.prototype.systemFiles = ['thumbs.db', 'desktop.ini', '.DS_Store']

FileDropArea.prototype.onDrop = function (e) {
  e.preventDefault()
  e.stopPropagation()

  var area = this

  area.onDragLeave(e)

  function getFilePromise(entry) {
    return new Promise(function (resolve, reject) {
      entry.file(resolve, reject)
    })
  }

  function getFilesPromise(e) {
    var items = e.dataTransfer.items

    if (!items) return Promise.resolve(e.dataTransfer.files)
    if (!items[0].webkitGetAsEntry) return Promise.resolve(e.dataTransfer.files)

    var files = []
    var promises = [],
      filePromise
    for (var i = 0; i < items.length; i++) {
      filePromise = getFilesRecursively(items[i].webkitGetAsEntry())
      if (filePromise) promises.push(filePromise)
    }

    return Promise.all(promises).then(function (arrays) {
      files = [].concat.apply([], arrays)
      return Promise.resolve(files)
    })
  }

  function readEntriesPromise(directoryReader) {
    return new Promise(function (resolve, reject) {
      directoryReader.readEntries(resolve, reject)
    })
  }

  function getFilesRecursively(entry, filterOSTempFiles) {
    if (entry.isFile) {
      // filter OS temp metadata files
      if (filterOSTempFiles && area.systemFiles.indexOf(entry.name) > -1) return
      else return getFilePromise(entry)
    } else if (entry.isDirectory) {
      return readEntriesPromise(entry.createReader()).then(function (entries) {
        var promises = [],
          filePromise
        for (var i = 0; i < entries.length; i++) {
          filePromise = getFilesRecursively(entries[i], true)
          if (filePromise) promises.push(filePromise)
        }

        return Promise.all(promises).then(function (arrays) {
          return [].concat.apply([], arrays)
        })
      })
    }
  }

  getFilesPromise(e)
    .then(function (files) {
      // transform FileList into simple Array
      files = Array.prototype.slice.call(files)

      var file = files[0]
      if (!file) return // nothing is dropped

      if (area.readAs) {
        var reader = new FileReader()
        reader.onerror = function (e) {
          area.onError('Cannot read file: ' + e.target.error)
        }

        // Create a closure to capture the file information.
        reader.onload = (function () {
          return function (evt) {
            area.onFiles(evt.target.result)
          }
        })(file)

        // readAsDataURL, readAsText, readAsArrayBuffer, e.g. read image file as a data url
        reader[area.readAs](file)
      } else area.onFiles(files.filter(file => !area.filterFileTypeRegexp || file.type.match(area.filterFileTypeRegexp)))
    })
    .catch(area.onError)
}

export default FileDropArea
