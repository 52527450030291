<template>
  <k-autocomplete
    v-model="items"
    v-bind="$attrs"
    v-on="$listeners"
    @input="handleInput"
    :search-input.sync="autocompleteInput"
    small-chips
    deletable-chips
    multiple
  >
    <template v-slot:selection="data">
      <v-chip
        class="chip"
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
        :key="getItemText(data)"
      >
                <span class="chip__content">
                    {{ getItemText(data) }}
                </span>
      </v-chip>
    </template>
  </k-autocomplete>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    showLimit: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      autocompleteInput: null,
      items: this.value || []
    }
  },
  watch: {
    value(value) {
      this.items = value || []
    }
  },
  computed: {
    overLimit() {
      return this.items.length - this.showLimit
    }
  },
  methods: {
    getItemText(data) {
      return this.$attrs['item-text'] ? data.item[this.$attrs['item-text']] : data.item
    },
    handleInput() {
      this.autocompleteInput = ''
    },
    remove(item) {
      let index = -1
      if (typeof item === 'object') {
        index = this.items.indexOf(item[this.$attrs['item-value']])
      } else {
        index = this.items.indexOf(item)
      }

      if (index >= 0) this.items.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

::v-deep .k-input.v-input {
  .v-input__slot {
    padding: 0 !important;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}

::v-deep .v-select__selections {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

::v-deep .chip .v-chip__close {
  background-color: $koderia-black !important;
  border-radius: 9999px !important;
  border: none !important;
  margin-right: 0.025rem !important;

  svg {
    width: 0.875rem !important;
    height: 0.875rem !important;
  }
}

.chip {
  font-size: 0.875rem;
  height: 2rem;
  border-radius: 0.375rem;
  background-color: white !important;
  border: 1px solid #d1d5db !important;
  padding: 0.5rem;
}

.chip__content {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 5rem;
  white-space: nowrap;
}

.has-more {
  padding-left: 0.25rem;
  color: $koderia-black;
}
</style>
