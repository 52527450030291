<template>
  <k-layout>
    <div class="questionaire-page">
      <div class="tw-bg-gray-900 tw-w-full tw-py-24 tw-text-gray-50 tw-text-center">
        <h1 class="tw-heading-1">{{ $t('questionaire.title') }}</h1>
        <p class="tw-heading-4 tw-mt-4">
          {{ $t('questionaire.tester') }}
        </p>
      </div>

      <div class="questionaire-container">
        <div class="kod-programmer-questionaire kod-shadow">
          <div v-if="step == 1">
            <h3>{{ $t('global.step') }} 1/2</h3>
            <p class="body-text">
              {{ $t('questionaire.descriptions.answer_questions') }}
            </p>
            <v-layout column class="kod-questionaire-window-content third">
              <div>
                <div class="kod-prax-technology-question remove-margin">
                  <p class="title-text">
                    {{ $t('questionaire.subtitles.total_experience') }}:
                  </p>
                  <v-slider
                    v-model="testerObject.overalPraxis"
                    :tick-labels="['‹1', '1', '2', '3', '4', '5', '6›']"
                    :max="6"
                    step="1"
                    ticks="always"
                    tick-size="0"
                    :color="colors.kodBlack"
                    :track-color="colors.kodWhitesmoke"
                  />
                </div>
                <div class="bonus-questions add-margin">
                  <p class="title-text">
                    {{ $t('questionaire.subtitles.type_of_tester') }}
                  </p>
                  <v-radio-group v-model="testerObject.manualTester" row class="kod-radio-group remove-no-margin">
                    <k-radio
                      :label="$t('questionaire.automated_tester')"
                      :value="false"
                      :color="colors.kodGreen"
                      class="body-text"
                    />

                    <k-radio
                      :label="$t('questionaire.manual_tester')"
                      :value="true"
                      :color="colors.kodBlack"
                      class="body-text radio-manual-tester"
                    />
                  </v-radio-group>
                  <v-expand-transition>
                    <div v-show="!testerObject.manualTester">
                      <p class="title-text">
                        {{ $t('questionaire.subtitles.ci_experience') }}
                      </p>
                      <v-radio-group v-model="testerObject.CIexperience" row class="kod-radio-group remove-no-margin">
                        <k-radio :label="$t('global.yes')" :value="true" :color="colors.kodGreen" class="body-text" />
                        <k-radio :label="$t('global.no')" :value="false" :color="colors.kodBlack" class="body-text" />
                      </v-radio-group>
                    </div>
                  </v-expand-transition>
                  <v-expand-transition>
                    <div v-show="!testerObject.manualTester">
                      <p class="title-text">
                        {{ $t('questionaire.subtitles.performance_experience') }}
                      </p>
                      <v-radio-group v-model="testerObject.performanceExperience" row class="kod-radio-group">
                        <k-radio :label="$t('global.yes')" :value="true" :color="colors.kodGreen" class="body-text" />
                        <k-radio :label="$t('global.no')" :value="false" :color="colors.kodBlack" class="body-text" />
                      </v-radio-group>
                    </div>
                  </v-expand-transition>
                </div>
              </div>
              <div class="questionaire-actions">
                <hr />
                <k-button color="secondary" id="ga_questionaire_tester-step-1" @click="step = 2">
                  {{ $t('global.actions.continue') }}
                </k-button>
              </div>
            </v-layout>
          </div>
          <div v-if="step == 2">
            <p class="small-text" @click="step = 1">
              ‹ {{ $t('global.actions.back') }}
            </p>
            <h3>{{ $t('global.step') }} 2/2</h3>
            <p class="body-text">
              {{ $t('questionaire.descriptions.third_step') }}
            </p>
            <v-layout column class="kod-questionaire-window-content fourth">
              <h4>
                {{ $t('questionaire.subtitles.about_you') }}
              </h4>
              <hr />
              <div class="questionaire-contact-info">
                <div>
                  <k-input
                    type="text"
                    :title="$t('global.inputs.first_name')"
                    :placeholder="$t('global.inputs.examples.name')"
                    :required="true"
                    v-model="testerObject.name"
                    :emptyNotice="formInvalid"
                  />
                </div>
                <div>
                  <k-input
                    type="email"
                    :title="$t('global.inputs.email')"
                    :placeholder="$t('global.inputs.examples.email')"
                    :required="true"
                    v-model="testerObject.email"
                    :emptyNotice="formInvalid"
                  />
                </div>
              </div>
              <div class="questionaire-gdpr">
                <h4>
                  {{ $t('gdpr.title') }}
                </h4>
                <hr />
                <k-gdpr
                  v-model="testerObject.gdpr"
                  :invalid="formInvalid && !testerObject.gdpr.gdprValue"
                  :gdprTooltip="$t('gdpr.descriptions.privacy_policy_tooltip')"
                  newsletterLabelText="gdpr.descriptions.receive_job_offers"
                  return-negative-newsletter-value
                />
              </div>
              <div class="questionaire-actions">
                <hr />
                <v-expand-transition>
                  <div v-show="formInvalid && isFormValid()" class="form-error-message">
                    <p>
                      {{ $t('global.form_errors.check_entered_information') }}
                    </p>
                  </div>
                </v-expand-transition>
                <k-button
                  color="primary"
                  id="ga_questionaire_tester-step-2"
                  @click="sendForm"
                  :loading="$store.state.QUESTIONAIRE.loading"
                  :disabled="$store.state.QUESTIONAIRE.loading"
                >
                  {{ $t('global.actions.complete') }}
                </k-button>
              </div>
            </v-layout>
          </div>
        </div>
      </div>
    </div>
  </k-layout>
</template>

<script>
import colors from '../sass/variables.scss'
import questionaireCalculator from '../addons/questionaire_calculator'

export default {
  name: 'TesterFormQuestionairePage',
  data() {
    return {
      step: 1,
      testerObject: {
        name: '',
        email: '',
        overalPraxis: 0,
        manualTester: true,
        CIexperience: false,
        performanceExperience: false,
        gdpr: {
          gdprValue: false,
          newsletterValue: true
        }
      },
      formInvalid: false
    }
  },
  computed: {
    colors: function () {
      return {
        kodBlack: colors.kodBlack,
        kodWhitesmoke: colors.kodWhitesmoke,
        kodGreen: colors.kodGreen
      }
    }
  },
  watch: {
    step: function () {
      this.$vuetify.goTo(0)
    }
  },
  methods: {
    sendForm: function () {
      if (this.isFormValid()) this.formInvalid = true
      else {
        questionaireCalculator.testerCalculator.isInputValid(this.testerObject)
        questionaireCalculator.testerCalculator.computeWage(this.testerObject)

        this.$store.dispatch('QUESTIONAIRE/setFormResultsData', this.testerObject)
        this.$store.dispatch('QUESTIONAIRE/saveTesterToDB', this.testerObject)
      }
    },
    isFormValid() {
      return (
        !this.testerObject.name ||
        this.testerObject.name === '' ||
        !this.testerObject.email ||
        this.testerObject.email === '' ||
        !this.validateMail(this.testerObject.email) ||
        !this.testerObject.gdpr ||
        !this.testerObject.gdpr.gdprValue
      )
    },
    validateMail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
      return re.test(email)
    }
  }
}
</script>

<style lang="scss">
@import "@web/sass/variables.scss";

.kod-programmer-questionaire {
  @include margin-top-multiplier(4);
  @include padding-top-multiplier(7);
  @include padding-bottom-multiplier(4);
  @include padding-left-multiplier(8);
  @include padding-right-multiplier(8);

  width: 100%;
  max-width: 51.5rem;

  @media (max-width: 63.938rem) {
    @include padding-top-multiplier(4);
    @include padding-bottom-multiplier(4);
    @include padding-left-multiplier(2);
    @include padding-right-multiplier(2);
  }

  background: white;

  p.small-text {
    &:first-child {
      cursor: pointer;
      @include margin-bottom-multiplier(1);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  hr {
    border: unset;
    border-bottom: 0.063rem solid $koderia-border-grey;
  }

  h3 {
    @include margin-bottom-multiplier(2);
  }

  p.body-text {
    @include margin-bottom-multiplier(6);
  }

  // overriding margins
  .custom-autocomplete {
    margin-top: 0;
    padding-top: 0 !important;
  }

  // intro - second step
  .kod-prax-technology-question {
    @include margin-top-multiplier(8);

    &.remove-margin {
      @include margin-top-multiplier(0);
    }

    p.title-text {
      > span {
        color: $koderia-green;
      }
    }

    .v-slider--horizontal {
      margin-left: 0;
      margin-right: 0;
    }
  }

  // additional questions for tester/programmer
  .bonus-questions {
    &.add-margin {
      @include margin-top-multiplier(8);
    }

    p.title-text {
      @include margin-bottom-multiplier(2);
    }

    .kod-radio-group {
      @include margin-bottom-multiplier(7);
      padding-top: 0;

      &:last-child {
        @include margin-bottom-multiplier(0);
      }

      &:last-child.remove-no-margin {
        @include padding-bottom-multiplier(7);
      }

      label {
        color: $koderia-black;
      }

      .v-input__slot {
        margin-bottom: 0;
      }

      .v-messages {
        display: none;
      }
    }
  }

  h4 {
    @include margin-bottom-multiplier(1);
  }

  .questionaire-contact-info {
    @include margin-top-multiplier(3);
    @include margin-bottom-multiplier(6);

    display: flex;
    flex-direction: row;

    > div {
      width: 50%;

      &:first-child {
        @include padding-right-multiplier(2);
      }

      &:last-child {
        @include padding-left-multiplier(2);
      }
    }

    @media (max-width: 63.938rem) {
      flex-direction: column;

      > div {
        width: 100%;

        &:first-child {
          @include padding-right-multiplier(0);
        }

        &:last-child {
          @include padding-top-multiplier(2);
          @include padding-left-multiplier(0);
        }
      }
    }
  }

  .kod-gdpr-checkbox {
    @include margin-top-multiplier(3);
  }

  .questionaire-actions {
    text-align: center;

    hr {
      @include margin-top-multiplier(6);
    }

    .new-kod-button {
      @include margin-top-multiplier(4);
      padding: 0.625rem 5.5rem !important;
    }
  }
}

.radio-manual-tester {
  @media (max-width: 63.938rem) {
    margin-top: 0.5rem;
  }
}
</style>
