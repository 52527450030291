<template>
  <div class="questionaire-page">
    <div class="questionaire-header">
      <h1>{{ $t('questionaire.title') }}</h1>
    </div>
    <div class="questionaire-container" :style="thankYouStyle">
      <div class="thank-you kod-programmer-questionaire kod-shadow">
        <img src="/web/projects/ok.svg" alt="finished" />
        <h2>{{ $t('global.titles.all_done') }}</h2>
        <p class="body-text" style="margin-bottom: 0 !important;">
          {{ $t('questionaire.descriptions.thank_you') }}
        </p>
        <k-button @click="$router.push({ name: 'Home', params: { locale } })" width="132">
          {{ $t('navigation.home') }}
        </k-button>
      </div>
    </div>

    <!--        <div v-if="projects.length > 0" class="questionaire-container job-listing-container">-->
    <!--            <div class="questionaire-job-listing">-->
    <!--                <div class="tw-text-sm tw-self-start questionaire-job-listing-title tw-leading-normal">-->
    <!--                    {{ $t('questionaire.subtitles.job_listing_title') }}-->
    <!--                </div>-->

    <!--                <div class="tw-mt-2 tw-grid tw-grid-cols-1 tw-gap-4" :class="gridColClass">-->
    <!--                    <project-preview-new v-for="project in projects" :project="project" :key="project.id" simple />-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
  </div>
</template>
<script>
import { getRouteLocale } from '@web/constants/language'
import { mapGetters } from 'vuex'
// import ProjectPreviewNew from "@web/projects/components/project-preview-new.vue";
// import {getProjectsByProfessionAndWage} from "@web/utils/projects";

export default {
  name: 'questionaire-thank-you',
  // components: {ProjectPreviewNew},
  computed: {
    // ...mapGetters("NEW_PROJECTS", ["allProjects"]),
    ...mapGetters('QUESTIONAIRE', ['formResults']),
    locale() {
      return getRouteLocale()
    },
    // projects() {
    //     return getProjectsByProfessionAndWage(this.allProjects, this.formResults)
    // },
    gridColClass() {
      return `md:tw-grid-cols-${ this.projects.length }`
    },
    thankYouStyle() {
      // return this.projects.length ? { paddingBottom: '2rem !important' } : undefined
      return undefined
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('QUESTIONAIRE/setFormResultsProfession', '')
    this.$store.dispatch('QUESTIONAIRE/setFormResultsData', {})

    next()
  }
}
</script>
<style lang="scss">
@import "@web/sass/variables.scss";

.thank-you {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 39rem;

  @include margin-top-multiplier(5);

  h2 {
    @include margin-top-multiplier(0);
  }

  .body-text {
    max-width: 27.625rem;
    @include margin-top-multiplier(2);
    @include margin-bottom-multiplier(0);
  }

  img {
    height: 4.5rem;
  }

  button {
    @include margin-top-multiplier(4);
  }
}

.questionaire-job-listing {
  width: 100%;
  max-width: 51.5rem;

  .questionaire-job-listing-title {
    color: #6C727F
  }
}
</style>
