import { render, staticRenderFns } from "./kod-cv.vue?vue&type=template&id=69cd79b0&"
import script from "./kod-cv.vue?vue&type=script&lang=js&"
export * from "./kod-cv.vue?vue&type=script&lang=js&"
import style0 from "./kod-cv.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KIcon from '@web/components/core/k-icon'
import KInput from '@web/components/core/k-input'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {KButton,KIcon,KInput,VBtn,VLayout})
